import { useIsProtoItemPage } from '@/domains/proto/proto-utils';
import { useAppCtxSelector, useAppStateMatches } from '../xstate';

export function useIsIntercomVisible(): boolean {
  const alwaysShowIntercomSiteIds = ['ks-docs'];
  // siteIds for sites that should not have intercom
  const alwaysHideIntercomSiteIds: string[] = [];
  const isLoggedIn = useAppStateMatches('user.loggedIn');
  const isLatestInstance = useAppCtxSelector(({ site }) => {
    if (!site) return false;
    return (
      site.contentSrc.type === 'cloud-authoring' &&
      site.contentSrc.instance.type === 'latest'
    );
  });
  const isProtoItemPage = useIsProtoItemPage();
  const siteId = useAppCtxSelector(({ site }) => site?.meta.siteId);

  if (!siteId) return false;

  if (isProtoItemPage) return false;

  /**
   * siteIds where Intercom should never be visible
   */
  if (alwaysHideIntercomSiteIds.includes(siteId)) return false;

  /**
   * siteIds where Intercom should always be visible
   */
  if (alwaysShowIntercomSiteIds.includes(siteId)) return true;

  /**
   * only show Intercom if the user is logged in and not on the latest instance
   * if a site wants to show Intercom regardless of the user's status,
   * it should be added to the alwaysShowIntercomSiteIds list
   */
  if (isLoggedIn && !isLatestInstance) return true;

  return false;
}
