import dynamic from 'next/dynamic';
import { Suspense } from 'react';
import { useIsIntercomVisible } from './intercom-utils';

const Intercom = dynamic(
  () => import('./intercom').then((mod) => mod.default),
  {
    ssr: false,
  },
);

export const IntercomWrapper = () => {
  const isIntercomVisible = useIsIntercomVisible();
  if (!isIntercomVisible) return null;
  return (
    <Suspense>
      <Intercom />
    </Suspense>
  );
};
