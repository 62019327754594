'use client';

import { useAppClientDataSelector } from '@/core';
import { useKsLocation } from '@/utils/ks-location.hooks';

export function useProtoPatternInfo() {
  return useAppClientDataSelector((ctx) => {
    const prId = ctx.db.settings.prototyping?.patternId;
    if (!prId) {
      throw new Error('Prototyping patternId is not set');
    }
    const pattern = ctx.patternsState.patterns[prId];
    if (!pattern) {
      throw new Error(
        `Prototyping patternId is set to ${prId} but no pattern found`,
      );
    }

    const templates = pattern.templates.map((t) => ({
      templateId: t.id,
      templateTitle: t.title,
      demos: t.demoIds.map((demoId) => {
        const demo = ctx.db.demos.byId[demoId];
        return {
          demoId,
          demoTitle: demo?.title,
          demoDescription: demo?.description,
        };
      }),
      rendererId: t.templateLanguageId,
      rendererTitle:
        ctx.patternsState.renderers[t.templateLanguageId]?.meta.title,
    }));
    const templatesByRendererId = templates.reduce<
      Record<
        string,
        {
          rendererId: string;
          rendererTitle: string;
          templates: typeof templates;
        }
      >
    >((acc, t) => {
      if (!acc[t.rendererId]) {
        acc[t.rendererId] = {
          rendererId: t.rendererId,
          rendererTitle: t.rendererTitle,
          templates: [t],
        };
      } else {
        acc[t.rendererId].templates.push(t);
      }
      return acc;
    }, {});

    return {
      protoPatternId: prId,
      templates,
      templatesByRendererId,
    };
  });
}

export function useProtoUrlInfo(): {
  rendererId: string;
  templateId: string;
  demoId?: string;
} {
  const loc = useKsLocation();
  if (loc.type === 'prototypes.item') {
    const { demoId, rendererId, templateId } = loc;
    return { demoId, rendererId, templateId };
  }
  if (loc.type === 'prototypes.list') {
    const { rendererId, templateId } = loc;
    return { rendererId, templateId };
  }
  return {
    rendererId: '',
    templateId: '',
    demoId: '',
  };
}

export function useIsProtoItemPage(): boolean {
  const loc = useKsLocation();
  return loc.type === 'prototypes.item';
}
