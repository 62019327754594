'use client';

import React, {
  Suspense,
  lazy,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useDebouncyFn } from 'use-debouncy';
import { FloatingAction, ToastStack } from '@knapsack/toby';
import { ErrorCatcher } from '@/utils/error-catcher';
import { useIsLargeScreen, useUiStateMatches } from '..';
import { LeftPanelProps } from './panels/left-panel';
import {
  layout,
  layoutBackToTopTrigger,
  layoutContent,
  layoutContentInner,
} from './section-layout.css';
import { IntercomWrapper } from '../intercom/intercom-wrapper';
import { useIsIntercomVisible } from '../intercom/intercom-utils';

const LeftPanel = lazy(() => import('./panels/left-panel'));
const RightPanel = lazy(() => import('./panels/right-panel'));

type LayoutProps = {
  header?: React.ReactNode;
  children: React.ReactNode;
  leftPanel?: LeftPanelProps;
  removeGutter?: boolean;
};

export const SectionLayout = ({
  children,
  header,
  leftPanel,
  removeGutter = false,
}: LayoutProps) => {
  const isLargeScreen = useIsLargeScreen();
  const isLeftPanelOpen = leftPanel && leftPanel.opened;
  const isRightPanelOpen = useUiStateMatches('rightPanel.opened');
  const isIntercomVisible = useIsIntercomVisible();

  // Back to Top
  const mainContentRef = useRef<HTMLDivElement>(null);
  const [isBackToTopVisible, setIsBackToTopVisible] = useState(false);

  const trackScroll = useCallback(() => {
    const scrollBuffer = 300;
    const scrolled = mainContentRef.current?.scrollTop ?? 0;
    setIsBackToTopVisible(scrolled > scrollBuffer);
  }, []);

  const debouncedTrackScroll = useDebouncyFn(trackScroll, 50);

  useEffect(() => {
    const currentMainContent = mainContentRef.current;
    currentMainContent?.addEventListener('scroll', debouncedTrackScroll);
    debouncedTrackScroll();
    return () => {
      currentMainContent?.removeEventListener('scroll', debouncedTrackScroll);
    };
  }, [debouncedTrackScroll, trackScroll]);

  return (
    <>
      {header && <Suspense>{header}</Suspense>}
      <div className={layout({ hasHeader: !!header })}>
        {leftPanel && <LeftPanel {...leftPanel} />}
        <main
          className={layoutContent({
            isLargeScreen,
            isLeftPanelOpen,
            isRightPanelOpen,
          })}
        >
          <div
            className={layoutContentInner({ removeGutter })}
            data-target="mainContent"
            onScroll={debouncedTrackScroll}
            ref={mainContentRef}
          >
            {children}
            <ToastStack />
          </div>
          <ErrorCatcher showToast>
            <Suspense>
              <IntercomWrapper />
            </Suspense>
          </ErrorCatcher>
          <FloatingAction
            onTrigger={() => {
              mainContentRef.current.scrollTo({ top: 0, behavior: 'smooth' });
            }}
            className={layoutBackToTopTrigger({
              visible: isBackToTopVisible,
              isIntercomVisible,
            })}
            disabled={!isBackToTopVisible}
            icon="arrow-up"
            label="Back to top"
            placement="bottom-right"
            tooltipPlacement="left"
            zIndex={100}
          />
        </main>
        <Suspense>
          <RightPanel />
        </Suspense>
      </div>
    </>
  );
};
